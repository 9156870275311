import isEqual from 'lodash/isEqual';

import {CreateBidFormValues} from 'pages/Bids/types';
import {initialCreateBidValues} from 'pages/Bids/data';

export const isCreateBidValuesAsInitial = (value: CreateBidFormValues): boolean =>
    isEqual(value, initialCreateBidValues);

export const isCreateBidValuesDifferent = (formValue: CreateBidFormValues, newValue?: CreateBidFormValues): boolean =>
    !isEqual(formValue, newValue || initialCreateBidValues);

export const getInitialValuesForForm = (
    createBidValues: CreateBidFormValues,
    updatedValues?: CreateBidFormValues,
    isOpenFromSearchButton?: boolean,
): CreateBidFormValues => {
    if (isOpenFromSearchButton) {
        return updatedValues ? {...initialCreateBidValues, ...updatedValues} : initialCreateBidValues;
    }

    if (
        updatedValues &&
        (isCreateBidValuesAsInitial(createBidValues) || isCreateBidValuesDifferent(createBidValues, updatedValues))
    ) {
        return {...createBidValues, ...updatedValues};
    }

    return createBidValues;
};
