import * as Yup from 'yup';

import {CustomerFormValues} from 'core/entities/Customer/types';

const validation = Yup.object<CustomerFormValues>({
    companyName: Yup.string().required('Please enter company name'),
    status: Yup.string().required('Please select company status'),
});

export default validation;
